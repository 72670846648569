import React from 'react'
import { RegionUpdate } from '../entry/RegionUpdate'

export const RegionDetail = () => {
  return (
    <div className=' grid'>
        <div className=' col-12'>
            <RegionUpdate />
        </div>
    </div>
  )
}
