import React from 'react'
import { CityUpdate } from '../entry/CityUpdate'

export const CityDetail = () => {
  return (
    <div className=' grid'>
        <div className=' col-12'>
            <CityUpdate />
        </div>
    </div>
  )
}
