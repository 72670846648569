import React from 'react'
import { TownShipUpdate } from '../entry/TownShipUpdate'

export const TownshipDetail = () => {
  return (
    <div className=' grid'>
        <div className=' col-12'>
            <TownShipUpdate />
        </div>
    </div>
  )
}
