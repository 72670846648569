import React from 'react'
import { RegionAndStateUpdate } from '../entry/RegionAndStateUpdate'

export const RegionAndStateDetail = () => {
  return (
    <div className=' grid'>
        <div className=' col-12'>
            <RegionAndStateUpdate />
        </div>
    </div>
  )
}
